import React from "react"
import { graphql } from "gatsby"
// import { Helmet } from 'react-helmet'

import { MDXRenderer } from "gatsby-plugin-mdx"

import Layout from '../components/layout';
import SEO from '../components/seo';
import CTA from '../components/blog/cta';
import Recommended from '../components/blog/recommended';
import Header from '../components/blog/header';

import "../styles/content.css"


// Page for a blog post
export default function Spotlight({data}) {
  const { post, recommended } = data;  // data.mdx holds your post data
  const { frontmatter, body } = post;
  const { author, published, updated, title, description, imageUrl, slug } = frontmatter;

  var subtitle = ``;
  if (author){
    subtitle += `by ${frontmatter.author}`;
  }

  var subsubtitle = '';
  if (published){
    subsubtitle = `Published ${published}`;
    if (updated){
      subsubtitle += `, updated ${updated}`
    }
  }

  const seoComponent = <SEO
    type="article" title={title}
    description={description || post.excerpt}
    imageUrl={imageUrl} path={slug} generateImage={true}
  />


  return (
    <Layout title={frontmatter.title} subtitle={subtitle} subsubtitle={subsubtitle}
      seoComponent={seoComponent}
      includeBanner={false} lightHeader={false}
    >
      <div className="content-body markdown-body">
        <Header title={frontmatter.title} subtitle={subtitle} subsubtitle={subsubtitle} />
        <MDXRenderer>{body}</MDXRenderer>
        <hr />
        <Recommended nodes={recommended.edges}/>
      </div>

      <div className="mt-10">
        <CTA type="spotlight"/>
      </div>
    </Layout>
  )
}
export const pageQuery = graphql`
  query($slug: String!, $recommended: [String]) {
    post: mdx(frontmatter: { slug: { eq: $slug } }) {
      body
      excerpt
      frontmatter {
        published
        updated
        author
        authorImage
        slug
        title
        imageUrl
        description
      }
    }
    recommended: allMdx(
      filter: { frontmatter: { slug : {in: $recommended}, type : {in: ["insight","how-to","spotlight","demo","podcast"]}, published: {ne: ""}}},
      sort: { order: DESC, fields: [frontmatter___published, frontmatter___slug] }
      limit: 3
    ) {
      edges {
        node {
          frontmatter {
            published
            updated
            author
            authorImage
            slug
            title
            imageUrl
            type
          }
        }
      }
    }
  }
`